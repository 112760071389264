<template>
  <div
    v-if="
      !menuList ||
      (menuList.length === 0 && (menuStore.FilterTags.length > 0 || menuStore.FilterStickers.length > 0))
    "
    class="v-d-flex v-flex-row v-flex-100 v-menu-title v-error-color"
    v-html="translate('productsListPage.notFoundFilter')"
  />
  <common-cards-flex-mesh
    v-else
    :items="
      menuList.filter((item) => {
        if (item.Product) {
          return applyProductsFilter(item.Product)
        }
        return true //do not filter banners
      })
    "
    :class-name="`v-card-product-${appConfig.VueSettingsPreRun.MenuItemsInRow}`"
    :max-items="appConfig.VueSettingsPreRun.MenuItemsInRow"
    :mesh-gap="appConfig.VueSettingsPreRun.MenuMeshGap"
    v-model:adaptive-items="adaptiveItems"
  >
    <template #item="item: ItemInList">
      <menu-product-in-list-banner-card
        v-if="item.Banners && item.Banners.length > 0"
        :banners="item.Banners"
      />
      <menu-product-in-list-item-card-by-type
        v-else-if="item.Product"
        :key="`list-fanuidhol-${item.Product.ID}`"
        :product="item.Product"
      />
    </template>
  </common-cards-flex-mesh>
</template>

<script setup lang="ts">
import { Guid } from '~api/consts'

import type { BannerInList, ItemInList } from '~types/menuStore'

import { useCommon } from '@arora/common'

const { isCommonGroup } = defineProps<{
  isCommonGroup: boolean
}>()
const { applyProductsFilter } = useProduct()
const { checkGuidEnable } = useCommon()
const appConfig = useAppConfig()
const menuStore = useMenuStore()
const adaptiveItems = ref<number>(appConfig.VueSettingsPreRun.MenuItemsInRow)

menuStore.CurrentItemsInRow = adaptiveItems.value

const { translate } = useI18nSanitized()
watch(
  () => adaptiveItems.value,
  () => {
    menuStore.CurrentItemsInRow = adaptiveItems.value
  }
)

const { data: menuList } = await useAsyncData('menuList', () => refreshProducts(), {
  watch: [menuStore.FilterStickers, menuStore.FilterTags]
})

watch(
  () => menuStore.Sorting,
  async (value, oldValue) => {
    if (value !== oldValue) menuList.value = await refreshProducts()
  }
)

async function refreshProducts(): Promise<ItemInList[]> {
  if (Guid.IsNullOrEmpty(menuStore.CurrentGroupId)) {
    return [] as ItemInList[]
  }
  const menuListItems = [] as ItemInList[]

  if (isCommonGroup) {
    const groupsIds = new Set(
      (appConfig.Groups.filter((group) => group.ParentGroupID === menuStore.CurrentGroupId) ?? []).map(
        (group) => group.ID
      )
    )

    for (const product of appConfig.Products) {
      if (groupsIds.has(product.GroupID)) menuListItems.push({ Product: product })
    }
  } else {
    for (const product of appConfig.Products) {
      if (product.GroupID === menuStore.CurrentGroupId) menuListItems.push({ Product: product })
    }
  }

  for (const banner of appConfig.Banners) {
    if (
      checkGuidEnable(banner.Active) &&
      (banner.GroupIDs.includes(menuStore.CurrentGroupId) || banner.ShowInEveryGroup)
    ) {
      if (banner.PositionInGroup >= menuListItems.length) {
        menuListItems.push({ Banners: [banner] })
      } else if ((menuListItems[banner.PositionInGroup].Banners ?? []).length > 0) {
        menuListItems[banner.PositionInGroup].Banners!.push(banner)
      } else {
        const result = [] as BannerInList[]
        result.push(banner)
        menuListItems.splice(banner.PositionInGroup, 0, { Banners: result })
      }
    }
  }

  if (menuListItems) {
    return await menuStore.applyFiltersAndSorting(menuListItems)
  }

  return [] as ItemInList[]
}
</script>
